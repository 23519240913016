
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

body {
 background-color:#f0ffff !important;
}

i {
 margin-right: 4%;
 font-size:1.4em;
}

.nb {
 display: inline-flex;
 justify-content: center;
 width:100%;
}

.fancy-btn{
 position: relative;
 display: inline-block;
 padding: 25px 30px;
 margin: 40px 0;
 color: #03e9f4;
 text-decoration: none !important;
 text-transform: uppercase !important;
 transition: 0.5s;
 letter-spacing: 4px;
 overflow: hidden;
 margin-right: 50px;
 filter: hue-rotate(180deg);
 -webkit-filter: hue-rotate(180deg);
 border-bottom: #000;
 border-width: 2px;
 border-style: double;
 border-top: none;
 border-right: none;
 border-left: none;
 text-align: center;
 width: 15%;
}

.fancy-btn-action {
 position: relative;
 display: inline-block;
 padding: 25px 30px;
 margin: 10% 0;
 color: #03e9f4;
 text-decoration: none !important;
 text-transform: uppercase !important;
 transition: 0.5s;
 letter-spacing: 4px;
 overflow: hidden;
 margin-right: 50px;
 filter: hue-rotate(180deg);
 -webkit-filter: hue-rotate(180deg);
 border-bottom: #000;
 border-width: 2px;
 border-style: double;
 border-top: none;
 border-right: none;
 border-left: none;
 text-align: center;
 margin: 0 auto; 
}

.fancy-btn:hover, .fancy-btn-action:hover{
 background: #03e9f4;
 border: none;
 color: #050801;
 box-shadow: 0 0 5px #03e9f4,
             0 0 25px #03e9f4,
             0 0 50px #03e9f4,
             0 0 200px #03e9f4;
}


.fancy-navbar {
 background-repeat: no-repeat;
 background-size:cover;
 text-align: center;
 
}

h1 {
 color:black;
 filter: hue-rotate(270deg);
 -webkit-filter: hue-rotate(270deg);
 font-size: 3.65em !important;
}

.jumbotron {
 display: flex !important;
 flex-direction: column;
 align-items: center;
}

.jmb-img {
 width: 15%
}

.text-wrapper {
 color:black;
 width:90%;
 display: flex;
 flex: 1;
 flex-direction: column;
 flex-wrap: wrap;
 align-items: center;
 align-content: center;
 justify-content: center;
 margin-top:1%;
}

.lead {
 font-size: 2em !important;
}

.sub {
 font-size: 1.25em;
 margin-top: 2%;
 margin-bottom: 2%;
}

#services {
 background-color: azure;
 text-align: center;
}


.service-card {
 width:35%;
 height: 30vh;
 position: relative;
 display: inline-block;
 padding: 25px 30px;
 margin: 40px 0;
 color: #03e9f4;
 text-decoration: none !important;
 text-transform: uppercase !important;
 transition: 0.5s;
 letter-spacing: 4px;
 overflow: hidden;
 margin-right: 50px;
 filter: hue-rotate(180deg);
 -webkit-filter: hue-rotate(180deg);
}

h3 {
 font-size: 1.55em !important;
}

.title {
 font-size: 0.65em !important;
}

.skill-item {
 font-weight: bold;
 letter-spacing: 1px;
}


.projects {
 color: black;
 width: 100%;
 text-align: center;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.projects-title {
 position: absolute;
 flex: 1;
 align-self:center;
 justify-self: flex-end;
 background-color: black;
 z-index: 1;
 opacity: 0.8;
 transition: ease-out 0.7s;
}

.projects-title h1 {
 color:white !important;
 filter:unset;
 font-size: 2.2em !important;
 transition: ease-out 0.7s;
 width: max-content;
}

.projects-card {
 width: 50%;
 text-align: center;
 display: flex;
 flex-direction: column;
 margin-top: 2%;
 background-color: #ddeded;
 padding: 40px;
 border-radius: 16px;
}

.projects-card-mobile {
 width: 50%;
 text-align: center;
 display: flex;
 flex-direction: column;
 margin-top: 2%;
 background-color: #ddeded;
 padding: 40px;
 border-radius: 16px;
}

.projects-img {
 width: 100%;
 z-index: 0;
 opacity: 0.6;
 text-align: center;
 transition: ease-in-out 0.7s;
}

.projects-img-mobile {
 width: 40%;
 z-index: 0;
 opacity: 0.6;
 text-align: center;
 transition: ease-in-out 0.7s;
 margin: 0 auto;
}

.projects-img:hover, .projects-img-mobile:hover {
 z-index: 2;
 opacity: 1;
 transition: ease-in-out 0.7s;
 cursor: pointer;
}

.projects-img img {
 width: 100%;
 height: 100%;
}


.projects-img-mobile img{
 width: 100%;
 height: 100%;
}

.contact {
 text-align: center;
 margin: 0 auto;
}

.contact h1 {
 color: black;
}

.form-group {
 width: 40%;
 margin: 0 auto;
}

.form-group input {
 width: 100% !important;
}

.fancy-separator {
 color:#03e9f4 !important;
 filter: hue-rotate(180deg);
 -webkit-filter: hue-rotate(180deg);
 height:4px !important;
 border: none;
}


input[type=submit] ,.fill {
 background:none;
   border:none;
   font-family:sans-serif;
   padding:1em 1.5em;
   color:#03e9f4;
   cursor:pointer;
   position:relative;
   overflow:hidden;
   transition:.2s ease all;
   z-index:2;
   border-radius:0px;
   filter: hue-rotate(180deg) !important;
   -webkit-filter: hue-rotate(180deg) !important;
}

input[type=submit]::before, .fill::before {
 position:absolute;
     left:calc(-100% + 3px);
     top:0;
     width:100%;
     height:100%;
     content:'';
     color:#fff;
     background:#03e9f4;
     transition:.2s ease all;
     z-index:-1;

}

input[type=submit]:hover, .fill:hover {
 color:#fff;
 border-radius:3px;
 background: transparent !important;
}

input[type=submit]:hover::before, .fill:hover::before {
 top:0;
 left:0%;
 width:100%;
 height:100%;
}

.footer-bs {
 background-color: white;
 padding: 30px 15px;
 color: black;
 border-bottom-right-radius: 6px;
 border-top-left-radius: 0px;
 border-bottom-left-radius: 6px;
}
.footer-bs .footer-brand, .footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { padding:10px 25px; }
.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { border-color: transparent; }


.footer-bs .footer-social h4 {
 font-size: 1.6em;
 text-transform: uppercase;
 letter-spacing: 3px;
 text-align: center;
}
.footer-social ul { display: flex; flex-direction: row; justify-content: center; font-size: 1.2em; }
.footer-bs .footer-social li { padding:5px 4px; list-style: none;}
.footer-bs .footer-social a { color:black; text-decoration: none;}
.footer-bs .footer-social a:hover { color:coral; text-decoration:none; }

.scale-in-center {
 -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
         animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-in-center-delayed {
 -webkit-animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
         animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 -webkit-animation-delay: 350ms;
         animation-delay: 350ms;
}

@-webkit-keyframes scale-in-center {
 0% {
   -webkit-transform: scale(0);
           transform: scale(0);
   opacity: 1;
 }
 100% {
   -webkit-transform: scale(1);
           transform: scale(1);
   opacity: 1;
 }
}
@keyframes scale-in-center {
 0% {
   -webkit-transform: scale(0);
           transform: scale(0);
   opacity: 1;
 }
 100% {
   -webkit-transform: scale(1);
           transform: scale(1);
   opacity: 1;
 }
}

@-webkit-keyframes scale-in-center-delayed {
 0% {
   -webkit-transform: scale(0);
           transform: scale(0);
   opacity: 1;
 }
 100% {
   -webkit-transform: scale(1);
           transform: scale(1);
   opacity: 1;
 }
}
@keyframes scale-in-center-delayed {
 0% {
   -webkit-transform: scale(0);
           transform: scale(0);
   opacity: 1;
 }
 50% {
   -webkit-transform: scale(0.5);
           transform: scale(0.5);
 }
 100% {
   -webkit-transform: scale(1);
           transform: scale(1);
   opacity: 1;
 }
}


@-webkit-keyframes slide-fwd-center {
 0% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
 }
 100% {
   -webkit-transform: translateZ(160px);
           transform: translateZ(160px);
 }
}
@keyframes slide-fwd-center {
 0% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
 }
 100% {
   -webkit-transform: translateZ(160px);
           transform: translateZ(160px);
 }
}

.slide-in-top {
 -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
         animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-top {
 0% {
   -webkit-transform: translateY(-1000px);
           transform: translateY(-1000px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateY(0);
           transform: translateY(0);
   opacity: 1;
 }
}
@keyframes slide-in-top {
 0% {
   -webkit-transform: translateY(-1000px);
           transform: translateY(-1000px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateY(0);
           transform: translateY(0);
   opacity: 1;
 }
}

#contact form button {
 width: 15%;
}

.nb-toggle {
 display: none;
}

@media only screen and (max-width: 600px) {

  .jmb-img {
    display: none;
   }

 .nb-toggle {
   display: block;
   font-size: 2.6em;
   margin-top:1%;
   width: fit-content;
   margin: 0 auto;
 }

 .nb {
   display: none;
   flex-direction: column;
   align-items: center;
 }

 .fancy-btn {
   width: 60%;
   text-align: center;
   margin: 0 auto;
   justify-content: space-between;
 }

 #services {
   display:flex;
   flex-direction: column;
   align-items: center;
 }

 .form-group {
   width: 80%;
 }

 #contact form button {
   width: 30%;
 }

 .service-card {
   margin-right: 0%;
   width: 90%;
   padding: 0;
   height:fit-content;
   margin-top: 1%;
 }

 .projects-card {
   width: 80%;
   padding: 24px;
 }

 .projects-card-mobile {
   width: 80%;
   padding: 24px;
 }


 .projects-img-mobile {
   width: 80%;
 }

 #contact form button {
   width: 30%;
 }


.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { border-left:solid 1px rgba(255,255,255,0.10); }
}


@media only screen and (max-width: 768px) and (min-width: 600px) {
  .jmb-img {
    display: none;
   }
 #services {
   display:flex;
   flex-direction: column;
   align-items: center;
 }

 .form-group {
   width: 80%;
 }

 #contact form button {
   width: 30%;
 }

 .service-card {
   margin-right: 0%;
   width: 90%;
   padding: 0;
   height:fit-content;
   margin-top: 1%;
 }

 .projects-card {
   width: 80%;
 }


.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns { border-left:solid 1px rgba(255,255,255,0.10); }
}

@media only screen and (max-width: 1000px) and (min-width: 768px) {
 .fancy-btn {
   letter-spacing: 1px;
   width: 22%;
 }

 .service-card {
   width: 45%;
   margin-right: 1%;
   letter-spacing: 2px;
 }
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
 .fancy-btn {
   letter-spacing: 1px;
   width: 22%;
 }

 .service-card {
   width: 48%;
   height: 40vh;
   margin-right: 1%;
   letter-spacing: 2px;
 }
}

label {
 font-size: 1.65em;
}

p {
 font-size:1.45em;
}

.subscriber-email {
 width: 100% !important;
}

.footer-logo {
 width: 50%;
 height: 55%;
 margin-bottom: 1%;
 filter: none !important;
 -webkit-filter: none !important;
}

